import { DateTime } from 'luxon';
import React from 'react';

export function useMergeRefs<Instance>(
  refs: Array<React.Ref<Instance> | undefined>
): React.RefCallback<Instance> | null {
  return React.useMemo(() => {
    if (refs.every((ref) => ref == null)) {
      return null;
    }

    return (value) => {
      refs.forEach((ref) => {
        if (typeof ref === 'function') {
          ref(value);
        } else if (ref != null) {
          (ref as React.RefObject<Instance | null>).current = value;
        }
      });

      // Clean up function
      return () => {
        refs.forEach((ref) => {
          if (typeof ref === 'function') {
            ref(null);
          } else if (ref != null) {
            (ref as React.RefObject<Instance | null>).current = null;
          }
        });
      };
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refs);
}

export const useDebounce = <T,>(value_: T, delay = 100): T => {
  const [value, setValue_] = React.useState(value_);

  const setValue = React.useCallback(
    (v) => {
      React.startTransition(() => {
        setValue_(v);
      });
    },
    [setValue_]
  );

  React.useEffect(() => {
    const id = setTimeout(() => setValue(value_), delay);
    return () => clearTimeout(id);
  }, [value_, delay, setValue]);

  return value;
};

export function usePrevious<T>(value: T): T {
  const ref = React.useRef<T>(null);
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function usePreviousIfFalsy<T>(value_: T, usePrev, timeout = 1000): T {
  const [value, setValue] = React.useState(value_);

  React.useEffect(() => {
    if (!usePrev) {
      setValue(value_);
      const id = setTimeout(() => setValue(value_), timeout);
      return () => clearTimeout(id);
    }
  }, [timeout, usePrev, value_]);

  return usePrev ? value : value_;
}

export function useIsWindowFocused() {
  const [isFocused, setIsFocused] = React.useState(true);

  const setFocused = () => {
    setIsFocused(true);
  };
  const setBlurred = () => {
    setIsFocused(false);
  };

  React.useEffect(() => {
    window.addEventListener('focus', setFocused);
    window.addEventListener('blur', setBlurred);
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('focus', setFocused);
      window.removeEventListener('blur', setBlurred);
    };
  });

  return isFocused;
}

async function checkIfOnline() {
  if (typeof navigator === 'undefined') {
    return true;
  }

  if (!window.navigator.onLine) return false;

  // avoid CORS errors with a request to your own origin
  const url = new URL(window.location.origin);

  // random value to prevent cached responses
  url.searchParams.set('rand', Math.random().toString(36).substring(2, 15));

  try {
    const response = await fetch(url.toString(), { method: 'HEAD' });
    return response.ok;
  } catch {
    return false;
  }
}

export function useIsOnline() {
  const isFocused = useIsWindowFocused();
  const [isOnline, setIsOnline] = React.useState(true);

  const setStatus = React.useCallback(async () => {
    if (!isFocused) {
      return;
    }
    const result = await checkIfOnline();
    setIsOnline(result);
  }, [isFocused]);

  React.useEffect(() => {
    const id = setInterval(setStatus, 10000);
    return () => clearInterval(id);
  }, [setStatus]);

  React.useEffect(() => {
    window.addEventListener('online', setStatus);
    window.addEventListener('offline', setStatus);

    return () => {
      window.removeEventListener('online', setStatus);
      window.removeEventListener('offline', setStatus);
    };
  }, [setStatus]);

  return isOnline;
}

export function useCurrentDateTime(interval = 1000, timezone = 'UTC') {
  const getTime = React.useCallback(
    () => DateTime.utc().setZone(timezone),
    [timezone]
  );

  const [dt, setDt] = React.useState(getTime());

  React.useEffect(() => {
    const intervalIdRef = { current: null };

    const timeoutId = setTimeout(() => {
      intervalIdRef.current = setInterval(() => {
        setDt(getTime());
      }, interval);
    }, 1000 - new Date().getMilliseconds());

    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalIdRef.current);
    };
  }, [getTime, interval]);

  return dt;
}

export function useStateWithRef<S>(initialState: S) {
  const [state, setState_] = React.useState<S>(initialState);
  const stateRef = React.useRef<S>(initialState);

  const setState = React.useCallback((stateOrFn) => {
    if (typeof stateOrFn === 'function') {
      const newState = stateOrFn(stateRef.current);
      setState_(newState);
      stateRef.current = newState;
      // setState_((oldState) => {
      //   const newState = stateOrFn(oldState);
      //   stateRef.current = newState;
      //   return newState;
      // });
    } else {
      setState_(stateOrFn);
      stateRef.current = stateOrFn;
    }
  }, []);

  return [state, stateRef, setState] as [
    S,
    React.RefObject<S>,
    typeof setState_,
  ];
}

// export function useStateWithRef<S>(initialState: S) {
//   const [state, setState_] = React.useState<S>(initialState);
//   const stateRef = React.useRef<S>(initialState);

//   const setState = React.useCallback((stateOrFn) => {
//     if (typeof stateOrFn === 'function') {
//       setState_((oldState) => {
//         const newState = stateOrFn(oldState);
//         stateRef.current = newState;
//         return newState;
//       });
//     } else {
//       setState_(stateOrFn);
//       stateRef.current = stateOrFn;
//     }
//   }, []);

//   return [state, stateRef, setState] as [
//     S,
//     React.MutableRefObject<S>,
//     typeof setState_
//   ];
// }

export const useNowDateTime = (interval = 60000) => {
  const [now, setNow] = React.useState(DateTime.utc());

  React.useEffect(() => {
    const timeoutId = setInterval(() => {
      setNow(DateTime.utc());
    }, interval);
    return () => clearInterval(timeoutId);
  }, [interval]);

  return now;
};
