import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { CroppedText } from 'shared/typography';
import Button from '../button';
import { Box, Flex, Grid } from '../grid';
import ScrollTabs from '../scroll-tabs';
import { Heading } from '../typography';
import Modal_, { ModalProps } from './component';

const nope = () => false;

export const withCloseWarning =
  (Component: React.ComponentType<any>) =>
  // eslint-disable-next-line react/display-name
  ({
    close,
    hasUnsavedChanges, // = nope,
    ...props
  }: {
    close: () => void;
    hasUnsavedChanges: boolean | (() => boolean);
  } & any) => {
    const [warningVisible, setWarningVisible] = React.useState(false);

    const checkedClose = React.useCallback(
      (skipCheck = false) => {
        if (
          skipCheck !== true &&
          (typeof hasUnsavedChanges === 'function'
            ? hasUnsavedChanges()
            : hasUnsavedChanges)
        ) {
          setWarningVisible(true);
        } else {
          if (close) {
            close();
          }
          setWarningVisible(false);
        }
      },
      [close, hasUnsavedChanges]
    );

    const dismiss = React.useCallback(() => {
      if (close) {
        close();
      }
      setWarningVisible(false);
    }, [close]);

    return (
      <>
        <Component {...props} close={close && checkedClose} />
        <Modal_ isOpen={warningVisible} close={() => setWarningVisible(false)}>
          {() => (
            <>
              <ModalHeader
                close={() => setWarningVisible(false)}
                title="Unsaved changes"
                type="alert"
              />

              <Grid p={3}>
                <CroppedText>
                  You have unsaved changes. Would you like to discard them?
                </CroppedText>

                <Flex justifyContent="flex-end" mt={3}>
                  <Button
                    mr={2}
                    variant="flat"
                    color="gray"
                    onClick={() => setWarningVisible(false)}
                  >
                    Back
                  </Button>

                  <Button
                    color="alert"
                    className="confirm-button"
                    onClick={dismiss}
                  >
                    Discard changes
                  </Button>
                </Flex>
              </Grid>
            </>
          )}
        </Modal_>
      </>
    );
  };

export function CloseButton({ ref = null, onClick, isDark = false, ...rest }) {
  return (
    <Box
      ref={ref}
      as="button"
      type="button"
      className="close"
      onClick={onClick}
      tabIndex={-1} // Stop it being the first focusable element
      sx={{
        cursor: 'pointer',
        color: isDark ? 'text' : 'white',
        fontSize: '16px',
        '&:hover, &:active': {
          color: isDark ? 'gray.6' : 'gray.1',
        },
      }}
      {...rest}
    >
      <FontAwesomeIcon size="lg" icon={faTimes} />
    </Box>
  );
}

const ModalHeader_ = ({
  close,
  title,
  tabs = null,
  type = '',
  leftCorner = null,
  rightCorner = null,
  useEllipsis = true,
}) => (
  <Box
    borderBottomStyle="solid"
    borderBottomColor="borderColor"
    borderBottomWidth="1px"
  >
    <Flex
      justifyContent="center"
      alignItems="center"
      className="header"
      px={3}
      flexWrap="nowrap"
      sx={{ position: 'relative' }}
      minHeight="50px"
    >
      <Flex alignItems="center">{leftCorner}</Flex>

      <Flex justifyContent="center" py={3} flexGrow={1}>
        <Heading
          fontSize={3}
          as="div"
          // maxWidth="calc(100% - 30px)"
          px={3}
          sx={
            useEllipsis
              ? {
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                  overflowWrap: 'break-word',
                }
              : null
          }
        >
          {title}
        </Heading>
      </Flex>
      <Flex alignItems="center" py={3} mr={0}>
        {rightCorner && <Box mr={2}>{rightCorner}</Box>}
        {close && <CloseButton isDark={true} onClick={close} />}
      </Flex>
    </Flex>
    {tabs && (
      <Box flex="1 1 auto" overflow="hidden" mb="-1px" alignSelf="flex-end">
        {tabs}
      </Box>
    )}
  </Box>
);

export const ModalHeader = withCloseWarning(ModalHeader_);

const Modal = withCloseWarning(Modal_);

export default Modal;

interface ConfirmModalProps {
  isOpen: boolean;
  title: string;
  close?: () => void;
  className?: string;
  type?: string;
  onConfirm?: () => Promise<any> | void;
  confirmText?: string;
  dismissText?: string;
  leftOfButtons?: string | React.ReactElement | React.JSX.Element;
  children;
}

export const ConfirmModal = ({
  isOpen,
  title,
  close,
  className = '',
  type = '',
  onConfirm = Promise.resolve,
  confirmText = '',
  dismissText = '',
  leftOfButtons = null,
  children,
}: ConfirmModalProps) => {
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (!isOpen) {
      setIsLoading(false);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} close={close} className={`${className} ${type}`}>
      {() => (
        <>
          <ModalHeader_ close={close} title={title} type={type} />

          <Grid p={3}>
            <Box>{children}</Box>

            <Flex justifyContent="space-between" mt={3}>
              <Box>{leftOfButtons}</Box>
              <Flex>
                {dismissText && (
                  <Button mr={2} variant="flat" color="gray" onClick={close}>
                    {dismissText || 'No'}
                  </Button>
                )}
                <Button
                  color={type || 'primary'}
                  className="confirm-button"
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={() => {
                    const result = onConfirm();
                    if (result && result.then) {
                      setIsLoading(true);

                      result
                        .then(() => {
                          setIsLoading(false);
                        })
                        .catch(() => {
                          setIsLoading(false);
                        });
                    }
                  }}
                >
                  {confirmText || 'Yes'}
                </Button>
              </Flex>
            </Flex>
          </Grid>
        </>
      )}
    </Modal>
  );
};

export const StandardModal = ({
  isOpen,
  title,
  close,
  leftCorner = null,
  rightCorner = null,
  hasUnsavedChanges = nope,
  children,
  ...rest
}: {
  title: React.JSX.Element | string;
  hasUnsavedChanges?: boolean | (() => boolean);
  type?: 'alert' | 'success';
  leftCorner?: React.JSX.Element | string;
  rightCorner?: React.JSX.Element | string;
  children;
} & Omit<ModalProps, 'children' | 'title'>) => {
  return (
    <Modal
      isOpen={isOpen}
      close={close}
      hasUnsavedChanges={hasUnsavedChanges}
      {...rest}
    >
      {(props) => (
        <>
          <ModalHeader
            title={title}
            close={props.close}
            leftCorner={leftCorner}
            rightCorner={rightCorner}
          />
          <Grid p={3}>{children({ ...props })}</Grid>
        </>
      )}
    </Modal>
  );
};

export const TabModal = ({
  isOpen,
  title,
  close,
  hasUnsavedChanges = nope,
  children,
  activeItem,
  items,
  setActiveItem,
  className,
  ...rest
}: {
  title: React.JSX.Element | string;
  hasUnsavedChanges?: boolean | (() => boolean);
  type?: 'alert' | 'success';
  activeItem: any;
  setActiveItem: (v: any) => void;
  items: any[];
  children;
} & Omit<ModalProps, 'children'>) => (
  <Modal
    isOpen={isOpen}
    close={close}
    className={`tab-modal ${className}`}
    hasUnsavedChanges={hasUnsavedChanges}
    {...rest}
  >
    {(props) => (
      <>
        <ModalHeader
          title={title}
          close={close}
          hasUnsavedChanges={hasUnsavedChanges}
          tabs={
            <ScrollTabs
              rtl={true}
              activeItem={activeItem}
              items={items}
              setActiveItem={setActiveItem}
            />
          }
        />
        <Grid p={3}>{children({ ...props, close })}</Grid>
      </>
    )}
  </Modal>
);
