import React from 'react';
// import { RemoveScroll } from 'shared/react-remove-scroll';
import {
  FloatingFocusManager,
  FloatingOverlay,
  useClick,
  useFloating,
  useId,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { RemoveScrollBar, zeroRightClassName } from 'react-remove-scroll-bar';
import { Transition } from 'react-transition-group';
import { Box, BoxProps } from 'shared/grid';
import { usePreviousIfFalsy } from 'shared/hooks';
import Portal from '../portal';
import Toaster from '../toaster';
import { ModalToastContext } from './context';

const modalZIndex = 6002;
const duration = 100;

const defaultStyle = {
  opacity: 0,
  // transform: 'translate3d(0, 0, 0)',
  // transition: `opacity ${duration}ms ease-out, transform ${duration}ms ease-out`,
  transition: `opacity ${duration}ms ease-out`,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export interface ModalWindowProps extends BoxProps {
  size?: 'tiny' | 'small' | 'medium' | 'large' | 'xlarge' | 'full';
  className?: string;
  close: any;
  style?: React.CSSProperties;
  children: any;
}

function ModalWindow({
  ref,
  size,
  className = '',
  close,
  style,
  children,
  sx,
  ...rest
}: ModalWindowProps) {
  const handleKeyDown = React.useCallback(
    (e) => {
      if (e.keyCode === 27) {
        close();
        e.stopPropagation();
      }
    },
    [close]
  );
  let modalWidth;

  switch (size) {
    case 'tiny':
      modalWidth = 324;
      break;
    case 'small':
      modalWidth = 540;
      break;
    case 'medium':
      modalWidth = 756;
      break;
    case 'large':
      modalWidth = 972;
      break;
    case 'xlarge':
      modalWidth = 1260;
      break;
    default:
      modalWidth = 600;
  }

  return (
    <Box
      {...rest}
      role="dialog"
      aria-modal="true"
      ref={ref}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      className={`modal ${className || ''}`}
      style={style}
      backgroundColor="background"
      sx={{
        ...(sx || {}),
        zIndex: modalZIndex + 1,
        /* Workaround android browser z-index bug */
        backfaceVisibility: 'hidden',
        padding: 0,
        border: 0,
        borderRadius: 2,

        outline: 'none',
        boxShadow: 'extraLarge',
        overflowY: 'visible',
        height: 'auto',
        position: 'relative',
        marginRight: 'auto',
        marginLeft: 'auto',
        minHeight: 0,
        maxWidth: `${modalWidth}px`,
        flexBasis: `${modalWidth}px`,
        marginY: [0, 3],

        ...(size === 'full'
          ? {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              width: '100%',
              maxWidth: 'none',
              height: '100%',
              minHeight: '100%',
              marginLeft: 0,
              border: 0,
              borderRadius: 0,
              marginY: 0,
            }
          : {}),
      }}
    >
      {children}
    </Box>
  );
}

export interface ModalProps<T = any> extends Omit<BoxProps, 'children'> {
  isOpen: boolean;
  close?: () => any;
  children: (
    props?: T & { close: () => void; backdropRef: any }
  ) => React.JSX.Element;
  autoFocus?: boolean;
  className?: string;
  noShrink?: boolean;
  size?: ModalWindowProps['size'];
  props?: any;
  zIndexDelta?: number;
}

const Modal_ = ({
  isOpen,
  close,
  children,
  autoFocus = true,
  className = '',
  noShrink = false,
  size = null,
  props,
  zIndexDelta: modalZIndexDelta = 0,
  ...rest
}: ModalProps) => {
  const modalBackdropRef = React.useRef<HTMLDivElement>(null);
  const clickStartedOnOverlayRef = React.useRef<boolean>(false);

  const ctx = React.useContext(ModalToastContext);
  const incrementModalCountRef = React.useRef(ctx.incrementModalCount);
  React.useEffect(() => {
    incrementModalCountRef.current = ctx.incrementModalCount;
  }, [ctx.incrementModalCount]);
  const decrementModalCountRef = React.useRef(ctx.decrementModalCount);
  React.useEffect(() => {
    decrementModalCountRef.current = ctx.decrementModalCount;
  }, [ctx.decrementModalCount]);

  const [viewPortOverlaySize, setViewPortOverlaySize] = React.useState(0);
  const pendingUpdateRef = React.useRef(false);

  React.useEffect(() => {
    function viewportHandler(event) {
      if (pendingUpdateRef.current) return;
      pendingUpdateRef.current = true;

      requestAnimationFrame(() => {
        pendingUpdateRef.current = false;

        const viewport = event.target;

        setViewPortOverlaySize(window.innerHeight - viewport.height);

        // const layoutViewport = document.getElementById("layoutViewport");

        // Since the bar is position: fixed we need to offset it by the
        // visual viewport's offset from the layout viewport origin.
        // const viewport = event.target;
        // const offsetLeft = viewport.offsetLeft;
        // const offsetTop =
        //   viewport.height -
        //   layoutViewport.getBoundingClientRect().height +
        //   viewport.offsetTop;

        // // You could also do this by setting style.left and style.top if you
        // // use width: 100% instead.
        // bottomBar.style.transform = `translate(${offsetLeft}px, ${offsetTop}px) scale(${
        //   1 / viewport.scale
        // })`;
      });
    }

    // window.visualViewport.addEventListener('scroll', viewportHandler);
    window.visualViewport?.addEventListener?.('resize', viewportHandler);

    return () => {
      // window.visualViewport.removeEventListener('scroll', viewportHandler);
      window.visualViewport?.removeEventListener?.('resize', viewportHandler);
    };
  }, []);

  const closeFn = React.useCallback(() => {
    if (close) {
      close();
      clickStartedOnOverlayRef.current = false;
    }
  }, [close]);

  const { context } = useFloating({
    open: isOpen,
    onOpenChange: closeFn,
  });

  const modalRef = React.useRef<any>(null);

  const id = useId();
  const labelId = `${id}-label`;
  const descriptionId = `${id}-description`;

  const { getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context),
    // useDismiss(context),
  ]);

  const opened = React.useCallback(() => {
    incrementModalCountRef.current();

    const focus = () => {
      if (autoFocus && modalRef.current) {
        const focusableEls = modalRef.current.querySelectorAll(
          'input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])'
        );

        let focusableEl;

        for (const el of focusableEls) {
          if (window.getComputedStyle(el).display !== 'none') {
            focusableEl = el;
            break;
          }
        }

        if (!focusableEl) {
          focusableEl = modalRef.current.querySelector(
            'a[href], area[href], button:not(.close):not(.popover)'
          ) as HTMLElement;
        }

        focusableEl?.focus?.();
      }
    };

    // For some reason this is not set initially
    setTimeout(focus);

    // ctx changes too much, so we disable the exhaustive deps check
  }, [autoFocus]);

  const closed = React.useCallback(() => {
    decrementModalCountRef.current();
  }, []);

  const isOpenBool = !!isOpen;
  const lastOpenValueRef = React.useRef(isOpenBool);

  React.useEffect(() => {
    if (lastOpenValueRef.current == isOpenBool) {
      return;
    }

    if (isOpenBool) {
      opened();
    } else {
      closed();
    }

    lastOpenValueRef.current = isOpenBool;
  }, [isOpenBool, closed, opened]);

  const oldProps = usePreviousIfFalsy(props, !isOpen, duration);

  return (
    <Portal>
      <Transition nodeRef={modalBackdropRef} in={!!isOpen} timeout={duration}>
        {(state) =>
          // Disable focus in shadow dom
          state !== 'exited' &&
          state !== 'unmounted' && (
            <>
              {/* <RemoveScroll
                enabled={isOpen}
                noIsolation={!!window['shadowRoot']}
              > */}
              <RemoveScrollBar />
              <Box
                as={FloatingOverlay}
                style={{
                  ...defaultStyle,
                  ...transitionStyles[state],
                }}
                ref={modalBackdropRef}
                className="modal-overlay"
                role="dialog"
                onClick={(event) => {
                  if (
                    clickStartedOnOverlayRef.current &&
                    modalBackdropRef.current === event.target
                  ) {
                    closeFn();
                  }
                  clickStartedOnOverlayRef.current = false;
                }}
                onMouseDown={(event) => {
                  // Prevents modal from closing when dragging out onto the backdrop
                  if (modalBackdropRef.current === event.target) {
                    clickStartedOnOverlayRef.current = true;
                  }
                }}
                sx={{
                  contain: 'content',
                  position: 'fixed',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  zIndex: modalZIndex + modalZIndexDelta,
                  backgroundColor: 'rgba(0, 0, 0, 0.45)',
                  WebkitOverflowScrolling: 'touch' /* NB 8 */,
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  overscrollBehavior: 'contain',
                  '--top-bar-height': `0px`,
                  // maxHeight: maxHeight,
                  // transform: maxHeight ? 'translateY(100%)' : null,

                  pb: viewPortOverlaySize,
                }}
              >
                <Box
                  sx={{
                    pointerEvents: 'none',
                    transition: 'transform 0.2s ease-out',
                    minHeight: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  className={zeroRightClassName}
                >
                  <FloatingFocusManager context={context} initialFocus={-1}>
                    <ModalWindow
                      {...rest}
                      ref={modalRef}
                      aria-labelledby={labelId}
                      aria-describedby={descriptionId}
                      {...getFloatingProps()}
                      className={`${className} ${zeroRightClassName}`}
                      close={closeFn}
                      size={size}
                      sx={{
                        pointerEvents: 'all',
                      }}
                    >
                      {children({
                        ...((!isOpen ? oldProps : props) || {}),
                        close,
                        backdropRef: modalBackdropRef,
                      })}
                    </ModalWindow>
                  </FloatingFocusManager>
                </Box>
              </Box>
              {/* </RemoveScroll> */}
            </>
          )
        }
      </Transition>
      {isOpen ? <Toaster inModal /> : null}
    </Portal>
  );
};

const Modal = React.memo(Modal_);

export default Modal;
